

<div class="flex justify-content-between px-3">
  <div class="text-xl">Comms Blockout Settings</div>
</div>

<div [style.display]="isShowProgressSpinner ? 'block' : 'none'" class="loadingContainer">
  <div class="overlay">
    <p-progressSpinner strokeWidth="7" class="spinner"></p-progressSpinner>
  </div>
</div>

<p-dialog header="Delete Confirmation" [(visible)]="isDeleteModalVisible" [style]="{width: '30vw'}">
 <div class="text-center">Are you sure you want to delete this {{toDeleteType === 'zipCode'? 'Zip codes' : 'Comms Block Setting' }}?</div>
 <div class="flex justify-content-end mt-4">
    <p-button label="Yes" icon="pi pi-check" [outlined]="true" (click)="onConfirmDelete()"></p-button>
 </div>
</p-dialog>

<p-dialog [header]="commonModalHeader" [(visible)]="isCommonModalVisible" [style]="{width: '30vw'}">
  <div class="text-center">{{commonModalMsg}}</div>
 </p-dialog>

 <p-sidebar [(visible)]="isSideBarVisible" styleClass="w-6" position="right">
  <div class="bg-white shadow-3 border-round p-4">
    <div class="flex justify-content-between align-items-center">
      <div class="text-xl">{{toEditId? 'Edit ' : 'Add New '}} Comms Blockout</div>

      <div class="flex align-items-center">
        <div>Is Active:</div>
        <p-toggleButton class="ml-2" [(ngModel)]="isActive"  onLabel="ON" offLabel="OFF"></p-toggleButton>
      </div>
    </div>

    <div class="mt-5">
      <p-floatLabel>
        <p-autoComplete 
           styleClass="w-full"
           [(ngModel)]="selectedCreditor" 
           [suggestions]="filteredCreditors"  
           (completeMethod)="filterCreditors($event)" 
           (onSelect)="onSelectCreditor($event)"
           [dropdown]="true" 
           optionLabel="CompanyName"
           placeholder="Select Creditor"
           id="creditors"
           >
           <ng-template let-creditor pTemplate="item">
             <div class="flex align-items-center justify-content-between flex-1">
              <div>{{creditor.CompanyName}}</div>
  
              <div>{{creditor.CreditorId}}</div>
             </div>
            </ng-template>
        </p-autoComplete>
        <label for="creditors">Creditor</label>
      </p-floatLabel>
    </div>

  
    <p-tabView [(activeIndex)]="activeZipCodeTabIndex">
      <p-tabPanel header="Upload File">
        <div class="flex justify-content-between align-items-center">
          <div class="flex align-items-center">
            <div>Zip Code CSV File:</div>
            <!--accept=".csv"-->
            <input type="file" #fileInput (change)="onFileSelected($event)"  style="display: none;" />
            <p-button class="ml-2" severity="secondary" label="Upload" icon="pi pi-file" (click)="fileInput.click()" [outlined]="true"></p-button>
          </div>
        </div>
        
        <div class="grid mt-4 px-2">
          <div class="col-10 text-xs font-semibold surface-50 border-bottom-1 border-300">
              Zip Codes
          </div>
    
          <div class="col-2 text-xs font-semibold surface-50 border-bottom-1 border-300">
              Action
          </div>
        </div>
    
        <div class="grid mt-1">
          <div class="col-10">
            {{zipCode}}
          </div>
    
          <div class="col-2">
            <i *ngIf="!!zipCode" class="pi pi-trash text-xs cursor-pointer" pTooltip="Delete" tooltipPosition="left" (click)="onOpenDeleteModal(null, 'zipCode')"></i>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Input Text">
        <div>
          <div>Please press enter or type comma after each zipcode or paste the whole list below</div>
          <div class="mt-2">
            <p-chips 
            class="full-width-chips"
            [(ngModel)]="zipCodeArray" 
            [separator]="separatorExp" 
            (onBlur)="onBlurZipCodeArray()"
            />
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
 



    

  <div class="flex align-items-center gap-3 mt-4">
    <div>Blockout Date Range:</div>
    <p-calendar 
      [(ngModel)]="startDate" 
      placeholder="From Date"
      [maxDate]="endDate"
    >
    </p-calendar>
    <p-calendar 
      placeholder="To Date"
      [(ngModel)]="endDate"        
      [minDate]="startDate"
     />
  </div>
  
  <textarea class="w-full mt-4" rows="5" cols="30" pInputTextarea placeholder="Reason" [(ngModel)]="reason"></textarea>

    <div class="flex justify-content-end mt-4">
      <p-button label="Save" icon="pi pi-send" (click)="onSave()" [outlined]="true"></p-button>
    </div>
  </div>
</p-sidebar>


<div class="flex justify-content-between mt-7 px-3">
  <div class="flex align-items-center">
    <p-multiSelect [options]="creditors"
                 [(ngModel)]="selectedCreditors"
                 placeholder="Select Creditors"
                 optionLabel="CompanyName"
                 filterBy="CreditorId,CompanyName">
            <ng-template let-creditor pTemplate="item">
                <div class="flex align-items-center justify-content-between flex-1">
                    <div>{{ creditor.CompanyName }}</div>
                    <div class="ml-2">{{ creditor.CreditorId }}</div>
                </div>
            </ng-template>
    </p-multiSelect>
    <p-button (click)="search()" styleClass="text-xs ml-1" label="Get" icon="pi pi-search text-xs" [outlined]="true" severity="secondary"></p-button> 
  </div> 
  <p-button label="Add New" icon="pi pi-plus" (click)="onOpenSideBar()" [outlined]="true"></p-button>
</div>

<div class="mt-4 pt-2 px-3">
  <p-table [value]="commsBlockoutSettings" [paginator]="true"
                                           [rows]="5"
                                           [tableStyle]="{ 'min-width': '50rem' }"
                                           [rowsPerPageOptions]="[5, 10, 20]">
        <ng-template pTemplate="header">
            <tr class="text-xs">
                <th>Creditor Id</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Zip Code</th>
                <th>Is Active</th>
                <th>Reason</th>
                <th>Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-comms>
            <tr class="text-xs">
                <td>{{ comms.CreditorId }}</td>
                <td>{{ comms.StartDate | date:'yyyy-MM-dd'}}</td>
                <td>{{ comms.EndDate | date:'yyyy-MM-dd'}}</td>
                <td>{{ comms.ZipCode }}</td>
                <td>{{ comms.IsActive }}</td>
                <td>{{ comms.Reason }}</td>
                <td>
                  <div class="flex align-items-center">
                    <i class="pi pi-pencil text-xs cursor-pointer" pTooltip="Edit" tooltipPosition="left" (click)="onOpenSideBar(comms.Id, comms.CreditorId, comms.StartDate, comms.EndDate, comms.ZipCode, comms.IsActive, comms.Reason )"></i>
                    <i class="ml-2 pi pi-trash text-xs cursor-pointer" pTooltip="Delete" tooltipPosition="left" (click)="onOpenDeleteModal(comms.CreditorId, 'commsBlockout')"></i>
                  </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
    </div>

