import { Inject, Injectable } from '@angular/core';
import { AppService } from '../../app.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationContactsService {
  private _etlApiBaseUrl: string = '';
  
  public constructor(private appService: AppService, public http: HttpClient, @Inject('ETL_API_BASE_URL') baseurl: string) {
    this._etlApiBaseUrl = baseurl;
  }

  public get = (creditorId: string): Observable<any> => {
    let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    const apipath: string = 'api/notification-contact?creditorId=creditor/'+creditorId;
    const fullPath: string = this._etlApiBaseUrl + apipath;
    const options = this.appService.getHttpHeaderOptions();

    return this.http.get<any>(fullPath, options);
  }

  public post = (creditorId: number, firstName: string, lastName: string, email: string): Observable<any> => {
    const apipath: string = 'api/notification-contact';
    const fullPath: string = this._etlApiBaseUrl + apipath;
    const options = this.appService.getHttpHeaderOptions();
    const params: any = { CreditorId: creditorId, FirstName: firstName, LastName: lastName, Email: email};

    return this.http.post<any>(fullPath, params, options);
  }

  public put = (notificationContactId: number, creditorId: number, firstName: string, lastName: string, email: string): Observable<any> => {
    const apipath: string = 'api/notification-contact';
    const fullPath: string = this._etlApiBaseUrl + apipath;
    const options = this.appService.getHttpHeaderOptions();
    const params: any = { NotificationContactId: notificationContactId, CreditorId: creditorId, FirstName: firstName, LastName: lastName, Email: email};

    return this.http.put<any>(fullPath, params, options);
  }

  public delete = (item: any): Observable<any> => {
    const apipath: string = 'api/notification-contact';
    const fullPath: string = this._etlApiBaseUrl + apipath;
    const options = this.appService.getHttpHeaderOptions();
    const body: any = { NotificationContactId: item.NotificationContactId, 
      CreditorId: item.CreditorId, 
      FirstName: item.FirstName, 
      LastName: item.LastName, 
      Email: item.Email};
      
    const fullOptions = {
        ...options, // Include other options like headers, params, etc.
        body,       // Add the body to the options object
      };

    return this.http.delete<any>(fullPath, fullOptions);
  }

}
