import { Component, Input } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'common-modal',
  templateUrl: './common-modal.component.html',
  styleUrl: './common-modal.component.css'
})
export class CommonModalComponent {
  constructor(public appService: AppService){
  }
}
