<div class="text-2xl font-bold bottom-padded">
    {{ selectedDataFileMappingType?.Name }} File Mapping for {{ selectedCreditor?.CompanyName }}
</div>
<br />
<div class="flex justify-content-between mt-5">
    <div class="flex flex-row gap-3 bottom-padded">
        <p-floatLabel>
            <p-dropdown id="creditors" [disabled]="itemId > 0" [options]="creditors" [(ngModel)]="selectedCreditorId" optionLabel="LongNameWithId" optionValue="CreditorId" placeholder="Select Creditor"></p-dropdown>
            <label for="creditors">Creditor</label>
        </p-floatLabel>
        <p-floatLabel>
            <p-dropdown id="mappingType" [disabled]="itemId > 0" [options]="dataFileMappingTypes" [(ngModel)]="selectedDataFileMappingType" optionLabel="Name" placeholder="Select Mapping Type"></p-dropdown>
            <label for="mappingType">File Mapping Type</label>
        </p-floatLabel>
        <p-button (click)="save()" label="{{ itemId <= 0 ? 'Create' : 'Save' }}" icon="pi pi-save" severity="success" [disabled]="(selectedCreditorId == '' || selectedDataFileMappingType == null) || (itemId > 0 && !isDirty)"></p-button>
        <p-button (click)="addDataFieldMapping()" [disabled]="itemId <= 0" label="Add New Field Mapping" icon="pi pi-plus" severity="info"></p-button>
        <p-button (click)="appService.navigateTo('filemappings')" label="Search Mappings" icon="pi pi-search" severity="secondary"></p-button>
    </div>
</div>
<div class="mt-3">
    <p-tabView (onChange)="handleTabChange($event)">
        <p-tabPanel header="Mappings Editor">
            <br />
            <br />
            <div *ngIf="dataFileMapping">
                <div *ngFor="let dataFieldMapping of dataFileMapping.FieldMappings">
                    <div class="card-container">
                        <div class="horizontal-flex-alignment">
                            <span title="Field Mapping ID: {{ dataFieldMapping.DataFieldMappingId }}" class="pi pi-sitemap icon"></span>
                            <span *ngIf="dataFieldMapping.IsRequiredOrEncouraged" title="Required / Encouraged Target Field" class="pi pi-star icon"></span>
                            <span *ngIf="!dataFieldMapping.IsRequiredOrEncouraged" title="Optional Target Field" class="pi pi-wrench icon"></span>
                            <div class="bottom-padded">
                                <p-floatLabel>
                                    <p-dropdown id="dataFieldMappingType" [options]="dataSourceFieldMappingTypes" [(ngModel)]="dataFieldMapping.SourceField.DataSourceFieldMappingTypeId" optionLabel="Name" optionValue="Id" placeholder="Select Field Mapping Type" class="fixed-width-dropdown"></p-dropdown>
                                    <label for="dataFieldMappingType">Source Mapping Type</label>
                                </p-floatLabel>
                            </div>
                            <div *ngIf="dataFieldMapping.SourceField.DataSourceFieldMappingTypeId == 0 || dataFieldMapping.SourceField.DataSourceFieldMappingTypeId == 1" class="bottom-padded">
                                <p-floatLabel>
                                    <input pInputText id="SourceFieldName" [(ngModel)]="dataFieldMapping.SourceField.Name" placeholder="Field Name" />
                                    <label for="SourceFieldName">Source Field Name</label>
                                </p-floatLabel>
                            </div>
                            <div *ngIf="dataFieldMapping.SourceField.DataSourceFieldMappingTypeId == 2" class="bottom-padded">
                                <p-floatLabel>
                                    <input pInputText id="SourceFieldConstantValue" [(ngModel)]="dataFieldMapping.SourceField.ConstantValue" placeholder="Constant Value" />
                                    <label for="SourceFieldConstantValue">Source Constant Value</label>
                                </p-floatLabel>
                            </div>
                            <span title="Show Enumerations" *ngIf="dataFieldMapping.SourceField.DataSourceFieldMappingTypeId == 3" (click)="toggleEnumerationEditor(dataFieldMapping.SourceField)" class="pi pi-window-maximize icon icon-button standard-field-width">
                                <div>{{ dataFieldMapping.SourceField.FieldMap.length }} Enumerations</div>
                            </span>
                            <p-card *ngIf="dataFieldMapping.SourceField.DataSourceFieldMappingTypeId == 3 && dataFieldMapping.SourceField.IsShowEnumerationEditor" class="card pseudo-modal-dialog-card">
                                <div class="enumeration-outer-container">
                                    <div *ngFor="let fieldMappingValue of dataFieldMapping.SourceField.FieldMap" class="enumeration-container bottom-padded">
                                        <p-floatLabel>
                                            <input pInputText id="SourceValue" [(ngModel)]="fieldMappingValue.SourceValue" placeholder="Source Value" />
                                            <label for="SourceValue">Source Value</label>
                                        </p-floatLabel>
                                        <p-floatLabel>
                                            <input pInputText id="TargetValue" [(ngModel)]="fieldMappingValue.TargetValue" placeholder="Target Value" />
                                            <label for="TargetValue">Target Value</label>
                                        </p-floatLabel>
                                        <button pButton type="button" label="Delete" icon="pi pi-trash" (click)="deleteFieldMappingValue(fieldMappingValue)" severity="warning"></button>
                                    </div>
                                    <p-button (click)="addFieldMappingValue(dataFieldMapping.SourceField)" label="Add Enum Mapping" icon="pi pi-plus" severity="info"></p-button>
                                    <p-button label="Close" icon="pi pi-window-minimize" (click)="toggleEnumerationEditor(dataFieldMapping.SourceField)" severity="secondary"></p-button>
                                </div>
                            </p-card>
                            <span title="Maps to" class="pi pi-caret-right icon"></span>
                            <div class="bottom-padded">
                                <p-floatLabel>
                                    <input pInputText id="TargetFieldName" [(ngModel)]="dataFieldMapping.TargetFieldName" [disabled]="dataFieldMapping.IsRequiredOrEncouraged" placeholder="Field Name" />
                                    <label for="TargetFieldName">Target Field Name</label>
                                </p-floatLabel>
                            </div>
                            <div class="bottom-padded">
                                <p-floatLabel>
                                    <p-dropdown id="stringFormatType" [options]="stringFormatTypes" [(ngModel)]="dataFieldMapping.StringFormatTypeId" optionLabel="Name" optionValue="Id" placeholder="Select String Format Type" class="fixed-width-dropdown">
                                    </p-dropdown>
                                    <label for="stringFormatType">Target Format Type</label>
                                </p-floatLabel>
                            </div>
                            <span *ngIf="dataFieldMapping.IsShowAdvancedFields == undefined || dataFieldMapping.IsShowAdvancedFields == false" title="Show advanced format fields" class="pi pi-angle-double-right icon icon-button" (click)="toggleAdvancedFields(dataFieldMapping)"></span>
                            <span *ngIf="dataFieldMapping.IsShowAdvancedFields" title="Hide advanced format fields" class="pi pi-angle-double-left icon icon-button" (click)="toggleAdvancedFields(dataFieldMapping)"></span>
                            <div *ngIf="dataFieldMapping.IsShowAdvancedFields" class="bottom-padded">
                                <p-floatLabel>
                                    <input pInputText id="Regex" [(ngModel)]="dataFieldMapping.Regex" placeholder="Regex" class="value-format-expression-field" />
                                    <label for="Regex">Regex</label>
                                </p-floatLabel>
                            </div>
                            <div *ngIf="dataFieldMapping.IsShowAdvancedFields" class="bottom-padded">
                                <p-floatLabel>
                                    <input pInputText id="DateTimeFormat" [(ngModel)]="dataFieldMapping.DateTimeFormat" placeholder="DateTime Format" class="value-format-expression-field" />
                                    <label for="DateTimeFormat">DateTime Format</label>
                                </p-floatLabel>
                            </div>
                            <div *ngIf="dataFieldMapping.IsShowAdvancedFields" class="bottom-padded">
                                <p-floatLabel>
                                    <input pInputText id="StringFormat" [(ngModel)]="dataFieldMapping.StringFormat" placeholder="String Format" class="value-format-expression-field" />
                                    <label for="String Format">String Format</label>
                                </p-floatLabel>
                            </div>
                            <div *ngIf="dataFieldMapping.IsShowAdvancedFields" class="bottom-padded">
                                <p-floatLabel>
                                    <input pInputText id="TimeZone" [(ngModel)]="dataFieldMapping.TimeZone" placeholder="TimeZone" class="value-format-expression-field" />
                                    <label for="TimeZone">TimeZone</label>
                                </p-floatLabel>
                            </div>
                            <button pButton type="button" label="Delete" icon="pi pi-trash" (click)="deleteFieldMapping(dataFieldMapping)" severity="warning" class="field-mapping-delete-button"></button>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel *ngIf="itemId > 0" header="Mappings JSON">
            <br />
            <textarea [(ngModel)]="fileMappingJson" name="fileMappingJson" readonly style="font-family:'Courier New';margin-left:8px;width:99%;height:calc(100vh - 390px);overflow:auto;background-color:aliceblue;color:black;">
            </textarea>
        </p-tabPanel>
    </p-tabView>
</div>
