<br/>
<br/>
<div class="text-lg">
    ETL Ingestion Result
</div>
<div class="flex justify-content-between mt-5">
    <div class="flex flex-row gap-3">
        <p-calendar [(ngModel)]="fromDate" 
                    [readonlyInput]="true" 
                    placeholder="Process Start Date"
                    [maxDate]="toDate"
                    [minDate]="minDate"
                    >
        </p-calendar>
        <p-multiSelect [options]="creditors"
                       [(ngModel)]="selectedCreditors"
                       placeholder="Select Creditors"
                       optionLabel="CompanyName"
                       filterBy="CreditorId,CompanyName">
            <ng-template let-creditor pTemplate="item">
                <div class="flex align-items-center justify-content-between flex-1">
                    <div>{{ creditor.CompanyName }}</div>
                    <div class="ml-2">{{ creditor.CreditorId }}</div>
                </div>
            </ng-template>
        </p-multiSelect>
        <p-button (click)="search()" styleClass="text-xs" label="Get" icon="pi pi-search text-xs" [outlined]="true" severity="secondary"></p-button>   
    </div>
</div>
<div class="mt-4">
    <p-table [value]="etlIngestionResults" [paginator]="true"
                                           [rows]="5"
                                           [tableStyle]="{ 'min-width': '50rem' }"
                                           [rowsPerPageOptions]="[5, 10, 20]">
        <ng-template pTemplate="header">
            <tr class="text-xs">
                <th>Creditor Id</th>
                <th>Creditor Name</th>
                <th>Process Start Time</th>
                <th>Total Processed Record Count</th>
                <th>Invoice Count</th>
                <th>Contact Count</th>
                <th>Voided Invoice Count</th>
                <th>ETL Ids</th>
                <th>Completion Message</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr class="text-xs">
                <td>{{ product.CreditorId }}</td>
                <td>{{ product.CreditorName }}</td>
                <td>{{ product.ProcessStartTime }}</td>
                <td>{{ product.TotalProcessedRecordCount }}</td>
                <td>{{ product.InvoiceCount }}</td>
                <td>{{ product.ContactCount }}</td>
                <td>{{ product.VoidedInvoiceCount }}</td>
                <td>{{ product.EtlIds}}</td>
                <td>{{ product.CompletionMessage }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
