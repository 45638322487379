import { Component } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'common-page-spinner',
  templateUrl: './common-page-spinner.component.html',
  styleUrl: './common-page-spinner.component.css'
})
export class CommonPageSpinnerComponent {
  constructor(public appService: AppService){}
}
