import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { AppService } from './app.service';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { MainComponent } from './components/main/main.component';
import { NavigatorComponent } from './components/navigator/navigator.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { ShortcutsComponent } from './components/shortcuts/shortcuts.component';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvoiceBasicTestComponent } from './components/invoice-basic-test/invoice-basic-test.component';
import { ContactBasicTestComponent } from './components/contact-basic-test/contact-basic-test.component';
import { NotificationReportComponent } from './components/notification-report/notification-report.component';
import { MatNativeDateModule } from '@angular/material/core';
import { InvoiceSearchComponent } from './components/invoice-search/invoice-search.component';
import { ContactSearchComponent } from './components/contact-search/contact-search.component';
import { LoginComponent } from './components/login/login.component';
import { AuthService } from './services/auth/auth.service';
import { UserBadgeComponent } from './components/user-badge/user-badge.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { RoleSearchComponent } from './components/role-search/role-search.component';
import { RoleEditComponent } from './components/role-edit/role-edit.component';
import { RouteListingComponent } from './components/route-listing/route-listing.component';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatStepperModule } from '@angular/material/stepper';
import { NavigationNodeEditComponent } from './components/navigation-node-edit/navigation-node-edit.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FunctionSearchComponent } from './components/function-search/function-search.component';
import { FunctionEditComponent } from './components/function-edit/function-edit.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { DateUtcToLocalPipe } from './pipes/date-utc-to-local.pipe';
import { MonthlyBillingReportComponent } from './components/monthly-billing-report/monthly-billing-report.component';  
import { SidebarModule } from 'primeng/sidebar';
import { MenubarModule } from 'primeng/menubar';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { EtlIngestionResultComponent } from './pages/etl-ingestion-result/etl-ingestion-result.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { FileMappingsComponent } from './pages/file-mappings/file-mappings.component';
import { FileMappingComponent } from './pages/file-mapping/file-mapping.component';
import { CardModule } from 'primeng/card';
import { FloatLabelModule } from 'primeng/floatlabel';
import { AccordionModule } from 'primeng/accordion';
import { FileUploadModule } from 'primeng/fileupload';
import { TabViewModule } from 'primeng/tabview';
import { DataViewModule } from 'primeng/dataview';
import { TagsComponent } from './pages/tags/tags.component';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PaginatorModule } from 'primeng/paginator';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MenuModule } from 'primeng/menu';
import { AvatarModule } from 'primeng/avatar';
import { ChipsModule } from 'primeng/chips';
import { CommsBlockoutSettingsComponent } from './pages/comms-blockout-settings/comms-blockout-settings.component';
import { ToggleButtonModule } from 'primeng/togglebutton';

@NgModule({
    declarations: [
        AppComponent,
        ModalDialogComponent,
        LoaderComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        BreadcrumbsComponent,
        MainComponent,
        NavigatorComponent,
        ShortcutsComponent,
        ContactBasicTestComponent,
        InvoiceBasicTestComponent,
        NotificationReportComponent,
        InvoiceSearchComponent,
        ContactSearchComponent,
        LoginComponent,
        UserBadgeComponent,
        UserSearchComponent,
        UserEditComponent,
        RoleSearchComponent,
        RoleEditComponent,
        RouteListingComponent,
        NavigationNodeEditComponent,
        UserProfileComponent,
        FunctionSearchComponent,
        FunctionEditComponent,
        DateAgoPipe,
        DateUtcToLocalPipe,
        MonthlyBillingReportComponent,
        EtlIngestionResultComponent,
        TagsComponent,
        FileMappingsComponent,
        FileMappingComponent,
        CommsBlockoutSettingsComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule, 
        AppRoutingModule,
        BrowserAnimationsModule,
        MatGridListModule, 
        MatSlideToggleModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        MatDividerModule,
        MatToolbarModule,
        MatCardModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        MatTooltipModule,
        MatSelectModule,
        MatTableModule,
        MatTreeModule,
        FormsModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatTabsModule,
        MatListModule,
        MatSortModule,
        MatPaginatorModule,
        MatMenuModule,
        MatExpansionModule,
        MatBadgeModule,
        MatStepperModule,
        MatCheckboxModule,
        SidebarModule,
        MenubarModule,
        ButtonModule,
        TooltipModule,
        TableModule,
        DropdownModule,
        CalendarModule,
        ButtonModule,
        InputTextModule,
        MultiSelectModule,
        ScrollPanelModule,
        CardModule,
        FloatLabelModule,
        AccordionModule,
        FileUploadModule,
        TabViewModule,
        DataViewModule,
        DialogModule,
        InputTextareaModule,
        ProgressSpinnerModule,
        PaginatorModule,
        AutoCompleteModule,
        PaginatorModule,
        MenuModule,
        AvatarModule,
        ToggleButtonModule,
        ChipsModule
    ],
    providers: [
        AuthService,
        AuthGuardService, 
        AppService,
        { provide: 'API_BASE_URL', useValue: environment.ApiBaseUrl },
        { provide: 'ETL_API_BASE_URL', useValue: environment.EtlApiBaseUrl },
        DateAgoPipe,
        DateUtcToLocalPipe
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
