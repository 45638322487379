import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { TagService } from '../../services/tag/tag.service';
import { EtlIngestionService } from '../../services/etl-ingestion/etl-ingestion.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { of } from 'rxjs'; // of() is used for creating an observable that emits a single value
import { AppService } from '../../app.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrl: './tags.component.css'
})
export class TagsComponent {
  isSideBarVisible: boolean = false;
  isDeleteModalVisible: boolean = false;
  tags: any;
  tagName: string ='';
  tagDesc: string = '';
  editedTagId: number = 0;
  toDeleteTagId: number = 0;
  creditors: any;
  selectedCreditor: any;
  _baseUrl:string = '';
  page: number = 1;
  rows: number = 10;
  totalRecords: number = 0;

  public constructor(
    private appService: AppService,
    private cdr: ChangeDetectorRef, 
    private tagService: TagService, 
    private etlIngestionService: EtlIngestionService,  
    @Inject('API_BASE_URL') baseurl: string) {
    this._baseUrl = baseurl;
   }
  

   onPageChange(event: any) {
       this.page = event.page+1;
       this.rows = event.rows;
       this.search();
   }

  ngOnInit(){
     this.etlIngestionService.getCreditors().subscribe((res:any) => {
      if(!!res && res.Creditors && Array.isArray(res.Creditors) && res.Creditors.length > 0){
        this.creditors = res.Creditors;
      }   
     });
  }

  search(){
    if(!!this.selectedCreditor){
      this.appService.isShowPageSpinner = true;
      this.tagService.getTags(this.selectedCreditor.CreditorId,this.page,this.rows)
        .pipe(
          tap((res: any ) => {
            if(!!res && !!res.items && Array.isArray(res.items) && res.items.length > 0){
              this.tags = res.items;
              this.totalRecords = res.totalCount;
            }  
          }),
          catchError(error => {          
            this.appService.openCommonModal('Error','There seems to be an error getting the tags.');
            return of(null); 
          }),
          finalize(() => {   
            this.appService.isShowPageSpinner = false;
          })
        ).subscribe();
     }
  }

  onOpenSideBar(id: number = 0, name: string = '', desc: string = ''){
    this.editedTagId = id;
    this.tagName = name;
    this.tagDesc = desc;
    this.isSideBarVisible = true;
  }

  onOpenDeleteModal(id: number){
    this.toDeleteTagId = id;
    this.isDeleteModalVisible = true;
  }

  onConfirmDelete(){
    if(!!this.toDeleteTagId){
      this.appService.isShowPageSpinner = true;

      this.tagService.deleteTag(this.toDeleteTagId).pipe(
        tap(() => {
          this.appService.commonModalHeader = 'Success';
          this.appService.commonModalMsg = 'Tag has been successfully deleted.';
          this.tags = this.tags.filter((i:any) => i.id !== this.toDeleteTagId);
          this.isDeleteModalVisible = false;
        }),
        catchError(error => {
          this.appService.commonModalHeader = 'Error';
          this.appService.commonModalMsg = 'There seems to be an error deleting the tag.';
          return of(null); // Handle the error and return an observable
        }),
        finalize(() => {
          this.appService.isCommonModalVisible = true;
          this.appService.isShowPageSpinner = false;
        })
      )
      .subscribe();
    }
  }

  onSave(){
    this.appService.isShowPageSpinner = true;
    if(!this.editedTagId){
      const campaignTagId = 'creditor/'+this.selectedCreditor.CreditorId+'/campaigntags/'+ Math.floor(Date.now() / 1000);
      const type = 'UserDefined';

      const newTags = JSON.parse(JSON.stringify(this.tags));
      this.tagService.createTag(this.selectedCreditor.CreditorId, this.tagName, this.tagDesc, campaignTagId, type)
      .pipe(
        tap((res: any ) => {
            this.appService.commonModalHeader = 'Success';
            this.appService.commonModalMsg = 'New tag has been successfully created.';
            this.isSideBarVisible = false;
            this.search();
        }),
        catchError(error => {
          this.appService.commonModalHeader = 'Error';
          this.appService.commonModalMsg = 'There seems to be an error saving the tag.';
          return of(null); 
        }),
        finalize(() => {
          this.appService.isCommonModalVisible = true;
          this.appService.isShowPageSpinner = false;
        })
      ).subscribe();
    }else{
      this.tagService.editTag(this.editedTagId, this.tagName, this.tagDesc)
      .pipe(
        tap(() => {
          this.appService.commonModalHeader = 'Success';
          this.appService.commonModalMsg = 'Tag has been successfully updated.';
          this.isSideBarVisible = false;

          const newTags = this.tags.map((item: any) => {
            if (item.id === this.editedTagId) {
              item.name = this.tagName; 
              item.description = this.tagDesc;
            }
            return item;
          });
          this.tags = newTags;
          this.cdr.detectChanges();
        }),
        catchError(error => {
          this.appService.commonModalHeader = 'Error';
          this.appService.commonModalMsg = 'There seems to be an error saving the tag.';
          return of(null); 
        }),
        finalize(() => {
          this.appService.isCommonModalVisible = true;
          this.appService.isShowPageSpinner = false;
        })
      )
      .subscribe();
    }   
  }


}
