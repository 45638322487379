import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppService } from './app.service';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { AuthService } from './services/auth/auth.service';
import { Router } from '@angular/router';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {
    visible: boolean = true;
    public title: string = "Remitter UI";
    public hideLabel: boolean = false;
    public isMasterMember: boolean = false;
    public isLoggedIn: boolean = false;
      myAccountMenuItems: any[] | undefined;

    public constructor(public authService: AuthService, 
                       public appService: AppService,
                       public router: Router
                    ) {
      let defaultAccountMenuItems: any = [
      {
        label: 'User Profile',
        icon: 'pi pi-user',
        command: () => {
           this.router.navigate(['/userprofile']);
        },
        },
        {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          command: () => {
             this.onLogout();
          },
        }
        ];
        this.myAccountMenuItems = defaultAccountMenuItems;
    }

    subMenuItems: any[] | undefined;
    menuItems: any[] | undefined;

    onClickMenu = (item: any) => {
        if(item.NavigationNodes.length > 0){
            this.router.navigate(['/navigate'])
            this.subMenuItems= item.NavigationNodes;
        }else{
            this.router.navigate(['/'+item.RoutePath]);
            this.subMenuItems=undefined;
            this.hideLabel = true;
        }
    }

    getSideBarWidth(){
        return this.hideLabel? '' : this.isMasterMember? '20vw' : '15vw';
    }

    onClickSubMenu = (item: any) => {
            if(item.NavigationNodes.length >  0) {
                this.subMenuItems = item.NavigationNodes;
            }else{
                this.router.navigate(['/'+item.RoutePath]);
                this.subMenuItems=undefined;
                this.hideLabel = true;
            }         
    }

    
    public edit = (navigationNode: any) => {

        this.appService.navigateTo('/navigationnode/' + navigationNode.NavigationNodeId);
    }

    public add = (navigationNode: any) => {

        this.appService.navigateTo('/navigationnode/0/' + navigationNode.NavigationNodeId);
    }

    getIcon(label:any){

        let returnValue = 'globe';
        switch(label){
            case 'Home':
                returnValue = 'home';
            break;
            case 'Administration':
                returnValue = 'cog';
            break;
            case 'Lab':
                returnValue = 'shop';
            break;
            case 'Reports':
                returnValue = 'chart-bar';
            break;
            case 'Operations':
                returnValue = 'sliders-v';
            break;
            case 'Profile':
                returnValue = 'user';
            break;
            case 'ETL':
                returnValue = 'file-import';
            break;
            case 'Tags':
                returnValue = 'tag';
            break;
        }

        return returnValue;
    }

    public ngOnInit() {       
        this.appService.hasLoggedOut.subscribe((r: any) => {
            this.menuItems = undefined;    
            this.subMenuItems=undefined;       
            this.authService.loggedOut.emit(true); 
            this.isLoggedIn = false;   
        });

        this.authService.loggedIn.subscribe((currentUser: any) => {
            this.appService.isLogInRoute = false;
            const currentSession = this.appService.getCurrentSession();

            if(!!currentSession){
                this.isLoggedIn = true;
                this.isMasterMember = currentSession.AuthenticatedUser.User.Roles.filter((i: string) => i.toUpperCase() == 'MASTER').length == 1;
                if(!this.menuItems){
                     this.appService.getNavigationNodes().subscribe((nodes: any) => {    
                         this.menuItems = nodes;
                     });
                 } 
            }        
         });

          if(!!this.appService.getCurrentSession() && !this.menuItems){
            this.isLoggedIn = true;
            this.appService.CurrentSession = this.appService.getCurrentSession();
            
            this.isMasterMember = this.appService.getCurrentSession().AuthenticatedUser.User.Roles.filter((i: string) => i.toUpperCase() == 'MASTER').length == 1;
            if(!this.menuItems){
                this.appService.getNavigationNodes().subscribe((nodes: any) => {    
                    this.menuItems = nodes;
                });
            }         
          }
       

        if (window.innerWidth < 700) {
            if (this.appService._isnavigationcollapsed == false) {

                this.appService.toggleNavigation();
            }
        }
    }

    public goToLanding(){
        this.appService.navigateTo('/landing');
    }

    public onLogout(){
        this.appService.logout();
        this.authService.logout();
    }

    public toggleTheme = () => {

        try {

            //if (this._isfullscreen == false) {

            //    ////window.document.body.requestFullscreen();
            //    setTimeout(function () {

            //        window.scrollTo(0, 1);

            //    }, 0);

            //    this._isfullscreen = true;

            //} else {

            //    ////window.document.exitFullscreen();
            //    setTimeout(function () {

            //        window.scrollTo(1, 1);

            //    }, 0);
            //    this._isfullscreen = false;
            //}

        } catch (e) {

            //alert(e.message);
        }
    }

    public toggleSidePane = () => {


    }
}
