import { Component, Inject, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { TagService } from '../../services/tag/tag.service';
import { EtlIngestionService } from '../../services/etl-ingestion/etl-ingestion.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { of } from 'rxjs'; // of() is used for creating an observable that emits a single value
import { AppService } from '../../app.service';
import { CommsBlockoutService } from '../../services/comms-blockout/comms-blockout.service';

@Component({
  selector: 'app-comms-blockout-settings',
  templateUrl: './comms-blockout-settings.component.html',
  styleUrl: './comms-blockout-settings.component.css'
})
export class CommsBlockoutSettingsComponent {
  @ViewChild('fileInput') fileInput: ElementRef | null = null;
  isDeleteModalVisible: boolean = false;
  isCommonModalVisible: boolean = false;
  isShowProgressSpinner: boolean = false;
  commonModalMsg:string = '';
  commonModalHeader:string = '';

  creditors: any | null = null;
  selectedCreditor: any | null = null;
  filteredCreditors: any[] = [];

  toEditId: number = 0;
  isActive: boolean = false;
  startDate: any = new Date();
  endDate: any = new Date();
  zipCode: any;
  reason: string = '';

  isSideBarVisible: boolean = false;
  commsBlockoutSettings!: any[]; 
  toDeleteId: number = 0;
  toDeleteType: string = '';

  selectedCreditors: any | undefined;

  zipCodeArray: string[] | undefined;
  separatorExp: RegExp = /,|\s|\n/;
  activeZipCodeTabIndex: number = 0;

  public constructor(private cdr: ChangeDetectorRef, private etlIngestionService: EtlIngestionService, private appService: AppService, private commsBlockoutService: CommsBlockoutService) { }

  ngOnInit() {    
    this.etlIngestionService.getCreditors().subscribe((res:any) => {
     if(!!res && !!res.Creditors && Array.isArray(res.Creditors) && res.Creditors.length > 0){
       this.creditors = res.Creditors;
     }   
    });

    this.search();   

   
  }
  
  search(){
    this.isShowProgressSpinner = true;

    let creditorInputs;

    if(this.selectedCreditors && Array.isArray(this.selectedCreditors) && this.selectedCreditors.length > 0){
      creditorInputs =  this.selectedCreditors.map((i: any) => i.CreditorId).join(',');
    }
    
    this.commsBlockoutService.searchCommsBlockout(creditorInputs)
      .pipe(
        tap((res: any ) => {
          if(!!res && Array.isArray(res) && res.length > 0){
            this.commsBlockoutSettings = res;
          }  
        }),
        catchError(error => {      
            this.commonModalHeader = 'Error';
            this.commonModalMsg = 'There seems to be an error getting the Comms Block Settings.';
            this.isCommonModalVisible = true;
            return of(null); 
        }),
        finalize(() => {   
          this.isShowProgressSpinner = false;
        })
      ).subscribe();
  }

  filterCreditors(event: any) {
    let filteredItems: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.creditors as any[]).length; i++) {
        let item = (this.creditors as any[])[i];
        if (item.CreditorId.indexOf(query) !== -1
        || item.CompanyName.toLowerCase().indexOf(query.toLowerCase()) !== -1
      ) {
            filteredItems.push(item);
        }
    }

    this.filteredCreditors = filteredItems;
  }

  onSelectCreditor(event:any){
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    //&& file.type === 'text/csv'
    if (file ) {
      const formData = new FormData();
      formData.append('file', file, file.name);
     
      this.commsBlockoutService.uploadZipCode(formData)
      .pipe(
        tap((res: any ) => {
            this.zipCode = '';
            this.zipCodeArray = undefined;
            if(!!res){
              this.zipCode = res.data;
              this.zipCodeArray = res.data.split(',');
            }
           
            this.commonModalHeader = 'Success';
            this.commonModalMsg = 'Zip Code File has been successfully uploaded.';
        }),
        catchError(error => {
          this.commonModalHeader = 'Error';
          this.commonModalMsg = 'There seems to be an error uploading the Zip Code.';
          return of(null); 
        }),
        finalize(() => {
          this.isCommonModalVisible = true;
          this.isShowProgressSpinner = false;
          if(this.fileInput){
             this.fileInput.nativeElement.value = ''; 
          }
        })
      ).subscribe();
    } else {
      this.commonModalHeader = 'Validation';
      this.commonModalMsg = 'Please select a valid csv file.';
      this.isCommonModalVisible = true;
    }
  }

  onOpenSideBar(id: number = 0, creditorId: number = 0, startDate:any = null, endDate: any = null, zipCode: any = null, isActive: boolean = false, reason: string = ''){
    this.isSideBarVisible = true;

   setTimeout(() => {
      const inputElement1: HTMLInputElement | null = document.querySelector('.full-width-chips > div');
      if(inputElement1){
        inputElement1.style.width = '100%';
      }
      
      const inputElement2: HTMLInputElement | null = document.querySelector('.p-chips-multiple-container');
      if(inputElement2){
        inputElement2.style.width = '100%';
      }
    },500);

    if(!!creditorId){
      this.selectedCreditor = this.creditors.find((i:any) => i.CreditorId == creditorId);
    }

    this.toEditId = id;
    this.startDate =  new Date(startDate);
    this.endDate = new Date(endDate);
    this.zipCode = zipCode;
    if(!!zipCode){
      this.zipCodeArray = zipCode.split(',');
    }
    this.isActive = isActive;
    this.reason = reason;
  }
  
  onOpenDeleteModal(id: any, type:string){
    this.toDeleteType = type;
    this.toDeleteId = id;
    this.isDeleteModalVisible = true;
  }
  
  onConfirmDelete(){
      if(this.toDeleteType === 'commsBlockout'){
        this.isShowProgressSpinner = true;
        this.commsBlockoutService.deleteCommsBlockout(this.toDeleteId).pipe(
          tap(() => {
            this.commonModalHeader = 'Success';
            this.commonModalMsg = 'Comms Block Settings has been successfully deleted.';
            this.isDeleteModalVisible = false;
            this.commsBlockoutSettings = this.commsBlockoutSettings.filter((i:any) => i.id !== this.toDeleteId);
            this.cdr.detectChanges();
          }),
          catchError(error => {
            this.commonModalHeader = 'Error';
            this.commonModalMsg = 'There seems to be an error deleting the Comms Block Settings.';
            return of(null); 
          }),
          finalize(() => {
            this.isCommonModalVisible = true;
            this.isShowProgressSpinner = false;
          })
        )
        .subscribe();
      }else{
        if(!!this.toEditId){
          this.isShowProgressSpinner = true;
          this.commsBlockoutService.deleteCommsBlockoutZipCode(this.toEditId).pipe(
            tap(() => {
              this.deleteZipCodeSuccess()
            }),
            catchError(error => {
              this.commonModalHeader = 'Error';
              this.commonModalMsg = 'There seems to be an error deleting the zip codes.';
              return of(null); 
            }),
            finalize(() => {
              this.isCommonModalVisible = true;
              this.isShowProgressSpinner = false;
            })
          )
          .subscribe();
        }else{
          this.deleteZipCodeSuccess();
        }
      }
  }

  deleteZipCodeSuccess(){
    this.commonModalHeader = 'Success';
    this.commonModalMsg = 'Zip Codes has been successfully deleted.';
    this.zipCode = null;
    this.zipCodeArray = undefined;
    this.isDeleteModalVisible = false;
    this.isCommonModalVisible = true;
  }

  onBlurZipCodeArray(){
    const inputElement: HTMLInputElement | null = document.querySelector('.p-chips-input-token input');

    if (inputElement && inputElement.value) {
      if(this.zipCodeArray){
        this.zipCodeArray.push(inputElement.value); // Add the last input as a chip
      }
    
      inputElement.value = ''; // Clear the input field
    }

    this.zipCode = this.zipCodeArray? this.zipCodeArray.join(',') : '';
  }

  validateInputs(){
    if(!this.selectedCreditor || !this.startDate || !this.endDate || !this.zipCode || !this.reason){
      this.commonModalHeader = 'Validation';
      this.commonModalMsg = 'Please provide all inputs';
      this.isCommonModalVisible = true;

      return false;
    }
    return true;
  }

  getProperDates(){
    const startDate = this.startDate.getFullYear() + '-' + (this.startDate.getMonth() + 1).toString().padStart(2, '0') + '-' + this.startDate.getDate().toString().padStart(2, '0'); 
    const endDate =  this.endDate.getFullYear() + '-' + (this.endDate.getMonth() + 1).toString().padStart(2, '0') + '-' + this.endDate.getDate().toString().padStart(2, '0'); 
    
    return {
      startDate,
      endDate
    }
  }

  onSave(){
    if(this.validateInputs()){
      if(!this.toEditId){
        const {startDate, endDate} = this.getProperDates();
  
        this.commsBlockoutService.createCommsBlockout(this.selectedCreditor.CreditorId, startDate, endDate, this.zipCode, this.isActive, this.reason)
        .pipe(
          tap((res: any ) => {
              this.commonModalHeader = 'Success';
              this.commonModalMsg = 'New Comms Block Setting has been successfully created.';
              this.isSideBarVisible = false;
              this.search();
          }),
          catchError(error => {
            this.commonModalHeader = 'Error';
            this.commonModalMsg = 'There seems to be an error saving the Comms Block Setting.';
            return of(null); 
          }),
          finalize(() => {
            this.isCommonModalVisible = true;
            this.isShowProgressSpinner = false;
          })
        ).subscribe();
      }else{
        const {startDate, endDate} = this.getProperDates();
  
        this.commsBlockoutService.editCommsBlockout(this.toEditId, +this.selectedCreditor.CreditorId, startDate, endDate, this.zipCode, this.isActive, this.reason)
        .pipe(
          tap(() => {
            this.commonModalHeader = 'Success';
            this.commonModalMsg = 'Comms Block Setting has been successfully updated.';
            this.isSideBarVisible = false;
  
            const newSettings = this.commsBlockoutSettings.map((item: any) => {
              if (item.Id === this.toEditId) {
                item.CreditorId = this.selectedCreditor.CreditorId;
                item.StartDate = startDate; 
                item.EndDate = endDate;
                item.ZipCode = this.zipCode;
                item.IsActive = this.isActive
                item.reason = this.reason;
              }
              return item;
            });
            this.commsBlockoutSettings = newSettings;
            this.cdr.detectChanges();
          }),
          catchError(error => {
            this.commonModalHeader = 'Error';
            this.commonModalMsg = 'There seems to be an error saving the Comms Block Setting.';
            return of(null); 
          }),
          finalize(() => {
            this.isCommonModalVisible = true;
            this.isShowProgressSpinner = false;
          })
        )
        .subscribe();
      }  
    }
  }
}
