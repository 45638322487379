import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from '../../app.service';

@Injectable({
  providedIn: 'root'
})
export class CommsBlockoutService {
  private _baseUrl: string = '';
  private _token: string  = '';

  public constructor(public appService: AppService, public http: HttpClient, @Inject('API_BASE_URL') baseurl: string) {
      this._baseUrl = baseurl;

      const loggedUserSession = localStorage.getItem('userSession');
      if(loggedUserSession){
        const loggedUserToken = JSON.parse(loggedUserSession).AuthenticatedUser.Token;
        this._token = loggedUserToken;
      }
  }

  public searchCommsBlockout = (creditorIds: any): Observable<any> => {
    let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    const apiPath: string = "CommsBlockout/search";
    const fullPath: string = this._baseUrl + apiPath;
    const options = this.appService.getHttpHeaderOptions();
    const params: any = {creditorIds: creditorIds || ''};

    return this.http.post<any>(fullPath, params, options);
  }

  public getCommsBlockout = (id: any): Observable<any> => {
    let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    const apiPath: string = "CommsBlockout/get?id="+id;
    const fullPath: string = this._baseUrl + apiPath;
    const options = this.appService.getHttpHeaderOptions();

    return this.http.get<any>(fullPath, options);
  }

  public uploadZipCode = (formData: any): Observable<any> => {
    const apiPath: string = 'CommsBlockout/uploadZipCode';
    const fullPath: string = this._baseUrl + apiPath;

    let headers;
    const loggedUserSession = localStorage.getItem('userSession');
    if (loggedUserSession) {
        const loggedUserToken = JSON.parse(loggedUserSession).AuthenticatedUser.Token;

        headers = new HttpHeaders({
        'Authorization': 'Bearer '+loggedUserToken,
        'Accept': '*/*'
      });
    }

    return this.http.post<any>(fullPath, formData, { headers });
  }

  public createCommsBlockout = (creditorId: number, startDate:any, endDate: any, zipCode: any, isActive: boolean, reason: string): Observable<any> => {
      const apiPath: string = 'CommsBlockout/add';
      const fullPath: string = this._baseUrl + apiPath;
      const options = this.appService.getHttpHeaderOptions();
      const params: any = {creditorId, startDate, endDate, zipCode, isActive, reason };

      return this.http.post<any>(fullPath, params, options);
  }

  public editCommsBlockout = (id: number, creditorId: number, startDate:any, endDate: any, zipCode: any, isActive: boolean, reason: string): Observable<any> => {
    const apiPath: string = 'CommsBlockout/update';
    const fullPath: string = this._baseUrl + apiPath;
    const options = this.appService.getHttpHeaderOptions();
    const params: any = {id, creditorId, startDate, endDate, zipCode, isActive, reason };

    return this.http.put<any>(fullPath, params, options);
 }

 public deleteCommsBlockoutZipCode = (id: any): Observable<any> => {
  const apiPath: string = 'CommsBlockout/deleteZipCode?id='+id;
  const fullPath: string = this._baseUrl + apiPath;
  const options = this.appService.getHttpHeaderOptions();

  return this.http.delete<any>(fullPath, options);
 }
 public deleteCommsBlockout = (id: any): Observable<any> => {
  const apiPath: string = 'CommsBlockout/delete?id='+id;
  const fullPath: string = this._baseUrl + apiPath;
  const options = this.appService.getHttpHeaderOptions();

  return this.http.delete<any>(fullPath, options);
 }

 
}
