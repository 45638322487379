import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from '../../app.service';

@Injectable({
    providedIn: 'root'
})

export class TagService {

    private _baseUrl: string = '';
    private _token: string  = '';

    public constructor(public appService: AppService, public http: HttpClient, @Inject('API_BASE_URL') baseurl: string) {
        this._baseUrl = baseurl;

        const loggedUserSession = localStorage.getItem('userSession');
        if(loggedUserSession){
          const loggedUserToken = JSON.parse(loggedUserSession).AuthenticatedUser.Token;
          this._token = loggedUserToken;
        }
    }

    public getTags = (creditorId: string, page:number = 1, pageSize: number = 10, sortBy: string = "", sortOrder:string = "ASC"): Observable<any> => {
      let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
      const apipath: string = "CampaignTags/creditors/"+creditorId+'?page='+page+'&pageSize='+pageSize+'&sortBy='+sortBy+'&sortOrder='+sortOrder;
      const fullPath: string = this._baseUrl + apipath;
      const options = this.appService.getHttpHeaderOptions();

      return this.http.get<any>(fullPath, options);
    }

    public createTag = (creditorId: string, name: string, description: string, tagId: string, type: string): Observable<any> => {
        const apipath: string = 'CampaignTags';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();
        const params: any = { creditorId, name, description, tagId, type};

        return this.http.post<any>(fullPath, params, options);
    }

    public editTag = (tagId: number, name: string, description: string): Observable<any> => {
      const apipath: string = 'CampaignTags/'+tagId;
      const fullPath: string = this._baseUrl + apipath;
      const options = this.appService.getHttpHeaderOptions();
      const params: any = { name, description, type: 'UserDefined' };

      return this.http.put<any>(fullPath, params, options);
   }

  public deleteTag = (tagId: number): Observable<any> => {
    const apipath: string = 'CampaignTags/'+tagId;
    const fullPath: string = this._baseUrl + apipath;
    const options = this.appService.getHttpHeaderOptions();

    return this.http.delete<any>(fullPath, options);
  }
}
