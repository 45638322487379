import { Component, DoCheck, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { Location } from '@angular/common';
import { ActivatedRoute, CanDeactivate } from '@angular/router';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { MappingService } from '../../services/mapping/mapping.service';
import { forkJoin } from 'rxjs';
import { ReferenceDataService } from '../../services/referencedata/reference-data.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-file-mapping',
    templateUrl: './file-mapping.component.html',
    styleUrl: './file-mapping.component.css'
})

export class FileMappingComponent implements OnInit, DoCheck {

    public itemId: number = 0;
    public creditors: any = null;
    public dataFileMappingTypes: any = null;
    public dataSourceFieldMappingTypes: any = null;
    public fileMappingDocument: any = null;
    public stringFormatTypes: any = null;
    public selectedCreditor: any | null = null;
    public selectedCreditorId: string | null = null;
    public selectedDataFileMappingType: any | null = null;
    public dataFileMapping: any | null = null;
    public dataFileMappingChangeTracking: any | null = null;
    public fileMappingJson: string = '{}';
    public dynamicClassAdvancedFieldsVisibility = "advanced-fields-hidden";
    public isDirty: boolean = false;

    public constructor(public appService: AppService,
                       private mappingService: MappingService,
                       private referenceDataService: ReferenceDataService,
                       private route: ActivatedRoute,
        private location: Location) { }

    public ngDoCheck(): void {

        if (JSON.stringify(this.dataFileMapping) != JSON.stringify(this.dataFileMappingChangeTracking)) {

            this.isDirty = true;
        }
    }

    public ngOnInit() {

        const parameters = this.route.params;

        if (parameters != null) {

            parameters.subscribe(p => {

                this.itemId = p['filemappingid'];

                forkJoin(this.referenceDataService.getCreditors(),
                         this.mappingService.getDataFileMappingTypes(),
                         this.mappingService.getDataSourceFieldMappingTypes(),
                         this.mappingService.getStringFormatTypes(),
                         this.mappingService.getDataFileMappingDocumentById(this.itemId)).subscribe(([creditors,
                                                                                                      dataFileMappingTypes,
                                                                                                      dataSourceFieldMappingTypes,
                                                                                                      stringFormatTypes,
                                                                                                      fileMappingDocument]) => {
                        this.creditors = creditors.map((i: any) => ({
                            ...i,
                            LongNameWithId: `${i.CompanyName} (${i.CreditorId})`
                        }));

                        if (dataFileMappingTypes.length > 0 && dataFileMappingTypes[0].Id == 0) {
                            dataFileMappingTypes.shift(); // get rid of 'unknown' type
                        }

                        if (dataSourceFieldMappingTypes.length > 0 && dataSourceFieldMappingTypes[0].Id == 0) {
                            dataSourceFieldMappingTypes.shift(); // get rid of 'unknown' type
                        }

                        if (stringFormatTypes.length > 0 && stringFormatTypes[0].Id == 0) {
                            stringFormatTypes[0].Name = '';
                        }

                        this.dataFileMappingTypes = dataFileMappingTypes;
                        this.dataSourceFieldMappingTypes = dataSourceFieldMappingTypes;
                        this.stringFormatTypes = stringFormatTypes;
                        this.dataFileMapping = fileMappingDocument;
                        this.dataFileMappingChangeTracking = JSON.parse(JSON.stringify(this.dataFileMapping)); // make a copy for change tracking
                        this.selectedCreditorId = fileMappingDocument.CreditorId; // parseInt(fileMappingDocument.CreditorId.split('/').pop());
                        this.selectedCreditor = this.creditors.find((i: any) => i.CreditorId === fileMappingDocument.CreditorId);
                        this.selectedDataFileMappingType = this.dataFileMappingTypes.find((i: any) => i.Id === fileMappingDocument.FileMappingTypeId);
                        this.fileMappingJson = JSON.stringify(fileMappingDocument, null, 2);
                    });
            });
        }
    }

    public onBasicUploadAuto = (e: any): void => {

        this.appService.showPendingFeatureMessage();
    }

    public uploadSampleCsv = (): void => {

        this.appService.showPendingFeatureMessage();
    }

    public validateSourceFieldNames = (): void => {

        this.appService.showPendingFeatureMessage();
    }

    public deleteFieldMapping = (dataFieldMapping: any): void => {

        const deletableFieldMapping = this.dataFileMapping.FieldMappings.find((i: any) => i.DataFieldMappingId == dataFieldMapping.DataFieldMappingId);

        if (deletableFieldMapping) {

            if (deletableFieldMapping.IsRequiredOrEncouraged) {

                this.appService.ShowModalDialog(ModalDialogSeverity.Warning, "Deletion Warning", "You are trying to delete a required (encouraged) field !  Are you sure ?", "YES I AM SURE", "NOT SURE").subscribe((response: string) => {

                    if (response == "YES I AM SURE") {

                        const itemIndex = this.dataFileMapping.FieldMappings.map((i: any) => i.DataFieldMappingId).indexOf(deletableFieldMapping.DataFieldMappingId, 0);

                        if (itemIndex > -1) {

                            this.dataFileMapping.FieldMappings.splice(itemIndex, 1);
                        }
                    }
                });
            }

            if (!deletableFieldMapping.IsRequiredOrEncouraged) {

                const itemIndex = this.dataFileMapping.FieldMappings.map((i: any) => i.DataFieldMappingId).indexOf(deletableFieldMapping.DataFieldMappingId, 0);

                if (itemIndex > -1) {

                    this.dataFileMapping.FieldMappings.splice(itemIndex, 1);
                }
            }
        }
    }

    public addDataFieldMapping = (): void => {

        if (this.itemId > 0) {

            const pendingNewFieldMappings: any = this.dataFileMapping.FieldMappings.filter((i: any) => i.DataFieldMappingId <= 0);
            let newDataFieldMappingPendingId: number = -1;

            if (pendingNewFieldMappings) {

                newDataFieldMappingPendingId = -1 * (pendingNewFieldMappings.length + 1);
            }

            const newFieldMapping = {
                CreatedBy: 'tmp',
                CreatedOnUtc: new Date(),
                DataFieldMappingId: newDataFieldMappingPendingId,
                DataFileMappingId: this.dataFileMapping.DataFileMappingId,
                SourceField: {
                    DataSourceFieldId: newDataFieldMappingPendingId,
                    DataFieldMappingId: newDataFieldMappingPendingId,
                    DataSourceFieldMappingTypeId: 1, 
                    Name: '',
                    ConstantValue: '',
                    FieldMap: []
                },
                StringFormatType: 0,
                StringFormatTypeId: 1, 
                TargetFieldName: ''
            };

            this.dataFileMapping.FieldMappings.push(newFieldMapping);
        }
    }

    public deleteFieldMappingValue = (fieldMappingValue: any): void => {

        const dataSourceField = this.getFieldMappingValueFieldMapping(fieldMappingValue);

        if (dataSourceField) {

            const itemIndex = dataSourceField.FieldMap.map((i: any) => i.FieldMappingValueId).indexOf(fieldMappingValue.FieldMappingValueId, 0);

            if (itemIndex > -1) {

                dataSourceField.FieldMap.splice(itemIndex, 1);
            }
        }
    }

    public addFieldMappingValue = (dataSourceField: any): void => {

        const pendingNewFieldMappingValues: any = dataSourceField.FieldMap.find((i: any) => i.DataSourceFieldId <= 0);
        let newDataFieldMappingValuePendingId: number = -1;

        if (pendingNewFieldMappingValues) {

            newDataFieldMappingValuePendingId = -1 * (pendingNewFieldMappingValues.length + 1);
        }

        const newFieldMappingValue: any = {
            FieldMappingValueId: newDataFieldMappingValuePendingId,
            DataSourceFieldId: dataSourceField.DataSourceFieldId,
            SourceValue: '',
            TargetValue: '',
            CreatedBy: 'tmp',
            CreatedOnUtc: new Date()
        };

        dataSourceField.FieldMap.push(newFieldMappingValue);
    }

    public toggleAdvancedFields = (dataFieldMapping: any): void => {

        if (dataFieldMapping.IsShowAdvancedFields == undefined || dataFieldMapping.IsShowAdvancedFields == false) {

            dataFieldMapping.IsShowAdvancedFields = true;

        } else {

            dataFieldMapping.IsShowAdvancedFields = false;
        }
    }

    public toggleEnumerationEditor = (dataSourceField: any): void => {

        if (dataSourceField.IsShowEnumerationEditor == undefined || dataSourceField.IsShowEnumerationEditor == false) {

            dataSourceField.IsShowEnumerationEditor = true;

        } else {

            dataSourceField.IsShowEnumerationEditor = false;
        }
    }

    public handleTabChange(event: any) {

        if (event.index == 1) { // json editor, update in case of any changes 

            this.fileMappingJson = JSON.stringify(this.dataFileMapping, null, 2);
        }
    }

    public save = (): void => {

        if (this.itemId <= 0) { // new mapping

            const creditorNumericId: number = Number(this.selectedCreditorId?.split('/').pop());

            this.mappingService.create(creditorNumericId, this.selectedDataFileMappingType.Id).subscribe((r: any | null) => {

                if (r != null) {

                    if (r.DataFileMappingId <= 0) {

                        this.appService.ShowModalDialog(ModalDialogSeverity.Warning, "Unable to Create Mapping", r.ExceptionMessage, "OK", "");

                    } else {

                        this.appService.navigateTo("filemapping/" + r.DataFileMappingId.toString());
                    }
                }
            });

        } else if (this.isDirty) { // even though the button is disabled, this is still called, so just ignore if no changes

            this.mappingService.save(this.dataFileMapping).subscribe((r: any | null) => {

                if (r != null) {

                    if (r instanceof HttpErrorResponse) {

                        this.appService.ShowModalDialog(ModalDialogSeverity.Error, "Error Saving Mapping", "There was an error saving the mapping document", "OK", "");

                    } else {

                        this.fileMappingDocument = r;
                        this.dataFileMappingChangeTracking = JSON.parse(JSON.stringify(this.dataFileMapping));
                        this.isDirty = false;

                        this.appService.ShowModalDialog(ModalDialogSeverity.Success, "Mapping Saved", "The mapping has been updated on the server", "OK", "");
                    }
                }
            });
        }
    }

    private getFieldMappingValueFieldMapping = (fieldMappingValue: any | null): any => {

        let result: any | null = null;
        const candidateDataFieldMapping: any | null = this.dataFileMapping.FieldMappings.find((i: any) => i.SourceField?.DataSourceFieldId == fieldMappingValue.DataSourceFieldId);

        if (candidateDataFieldMapping) {

            result = candidateDataFieldMapping.SourceField;
        }

        return result;

    }
}

