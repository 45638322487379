
<div class="flex justify-content-between px-3">
  <div class="text-xl">Tag Management</div>
</div>

<div [style.display]="isShowProgressSpinner ? 'block' : 'none'" class="loadingContainer">
  <div class="overlay">
    <p-progressSpinner strokeWidth="7" class="spinner"></p-progressSpinner>
  </div>
</div>

<p-dialog header="Delete Confirmation" [(visible)]="isDeleteModalVisible" [style]="{width: '30vw'}">
 <div class="text-center">Are you sure you want to delete this tag?</div>
 <div class="flex justify-content-end mt-4">
    <p-button label="Yes" icon="pi pi-check" [outlined]="true" (click)="onConfirmDelete()"></p-button>
 </div>
</p-dialog>

<p-dialog [header]="commonModalHeader" [(visible)]="isCommonModalVisible" [style]="{width: '30vw'}">
  <div class="text-center">{{commonModalMsg}}</div>
 </p-dialog>

<p-sidebar [(visible)]="isSideBarVisible" styleClass="w-4" position="right">
  <div class="bg-white shadow-3 border-round p-4">
    <div class="text-xl">{{editedTagId? 'Edit ' : 'Add New '}} Tag</div>
    <input [(ngModel)]="tagName" type="text" pInputText  class="w-full mt-4" placeholder="Tag Name" />
    <textarea class="w-full mt-2" rows="5" cols="30" pInputTextarea placeholder="Tag Description" [(ngModel)]="tagDesc"></textarea>
    <div class="flex justify-content-end mt-4">
      <p-button label="Save" icon="pi pi-send" (click)="onSave()" [outlined]="true"></p-button>
    </div>
  </div>
</p-sidebar>

<div class="flex justify-content-between mt-4 px-3">
  <div class="flex align-items-center">
    <p-dropdown 
  [options]="creditors"
  [(ngModel)]="selectedCreditor"
  placeholder="Select Creditor"
  optionLabel="CompanyName"
  filterBy="CreditorId,CompanyName">
      <ng-template let-creditor pTemplate="item">
        <div class="flex align-items-center justify-content-between flex-1">
          <div>{{ creditor.CompanyName }}</div>
          <div class="ml-2">{{ creditor.CreditorId }}</div>
        </div>
      </ng-template>
   </p-dropdown>
   <p-button class="ml-2" (click)="search()" label="Get Tags" icon="pi pi-search" severity="secondary" [outlined]="true"></p-button>   
  </div>

  <p-button label="Add New" icon="pi pi-plus" (click)="onOpenSideBar()" [outlined]="true"></p-button>
</div>

<div class="mt-4 pt-2 px-3">
  <p-dataView
  #dv
  [value]="tags"
  layout="grid"
  [rows]="24"
>
  <ng-template pTemplate="gridItem" let-tags>

    <div class="grid">
      <div class="col-2" *ngFor="let tag of tags">
        <div class="bg-white shadow-3 border-round p-3">
            <div [pTooltip]="tag.name" tooltipPosition="left" class="font-semibold tag-name">
              {{tag.name}}
            </div>
            <div [pTooltip]="tag.description" tooltipPosition="left" class="text-400 mt-1 text-sm tag-desc">
              {{tag.description}}
            </div>
            <div class="flex justify-content-end mt-2">
              <div class="flex align-items-center">
                <i class="pi pi-pencil text-xs cursor-pointer"  pTooltip="Edit" tooltipPosition="left" (click)="onOpenSideBar(tag.id, tag.name, tag.description)"></i>
                <i class="ml-2 pi pi-trash text-xs cursor-pointer" pTooltip="Delete" tooltipPosition="left" (click)="onOpenDeleteModal(tag.id)"></i>
              </div>
            </div>
        </div>
      </div>
    </div>

    
  </ng-template>
</p-dataView>
<p-paginator 
    (onPageChange)="onPageChange($event)" 
    [rows]="rows" 
    [totalRecords]="totalRecords" 
    [rowsPerPageOptions]="[10, 30, 50]" />
</div>
