<br/>
<br/>
<div class="ml-3">
  <div class="text-lg">
    File Ingestion Config
</div>

<div class="flex justify-content-between mt-4 px-4">
    <div class="flex flex-row gap-3">
      <p-dropdown 
      [options]="creditors"
      [(ngModel)]="selectedCreditor"
      (onChange)="onChangeCreditor($event)"
      placeholder="Select Creditor"
      optionLabel="CompanyName"
      filterBy="CreditorId,CompanyName">
          <ng-template let-creditor pTemplate="item">
            <div class="flex align-items-center justify-content-between flex-1">
              <div>{{ creditor.CompanyName }}</div>
              <div class="ml-2">{{ creditor.CreditorId }}</div>
            </div>
          </ng-template>
       </p-dropdown>
        <p-button (click)="search()" styleClass="text-xs" label="Get" icon="pi pi-search text-xs" [outlined]="true" severity="secondary"></p-button>   
    </div>

    
    <p-button label="Add New" icon="pi pi-plus" (click)="onOpenSideBar()" [outlined]="true"></p-button>
</div>
<div class="mt-4">
    <p-table [value]="fileIngestionConfigs" [paginator]="true"
                                           [rows]="5"
                                           [tableStyle]="{ 'min-width': '50rem' }"
                                           [rowsPerPageOptions]="[5, 10, 20]">
        <ng-template pTemplate="header">
            <tr class="text-xs">
                <th>File Ingestion Id</th>
                <th>Overwrite Amount DueOnly</th>
                <th>Missing Invoices Status Id</th>    
                <th>Action</th>         
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contact>
            <tr class="text-xs">
                <td>{{ contact.FileIngestionConfigId }}</td>
                <td>{{ contact.OverwriteAmountDueOnly }}</td>
                <td>{{ contact.MissingInvoicesStatusId }}</td>   
                <td>
                    <div class="flex align-items-center">
                        <i class="pi pi-pencil text-xs cursor-pointer"  pTooltip="Edit" tooltipPosition="left" (click)="onOpenSideBar(contact.FileIngestionConfigId, contact.OverwriteAmountDueOnly, contact.MissingInvoicesStatusId)"></i>
                        <i class="ml-2 pi pi-trash text-xs cursor-pointer" pTooltip="Delete" tooltipPosition="left" (click)="onOpenDeleteModal(contact)"></i>
                      </div>
                </td>       
            </tr>
        </ng-template>
    </p-table>
</div>

</div>

<common-delete-modal 
  [(visible)]="isDeleteModalVisible" 
  message="Are you sure you want to delete this File Ingestion Config?" 
  width="30vw"
  (actionClicked)="onConfirmDelete()">
</common-delete-modal>

<p-sidebar [(visible)]="isSideBarVisible" styleClass="w-4" position="right">
    <div class="bg-white shadow-3 border-round p-4">
      <div class="text-xl">{{false? 'Edit ' : 'Add New '}} File Ingestion Config</div>
  
      <div class="flex align-items-center mt-4 gap-2">
        <div>Overwrite Amount Due Only</div>
        <p-inputSwitch [(ngModel)]="overwriteAmountDueOnly" />
      </div>
      <input type="text" pInputText  class="w-full mt-2" placeholder="Missing Invoices Status Id" [(ngModel)]="missingInvoicesStatusId"/>
      <div class="flex justify-content-end mt-4">
        <p-button label="Save" icon="pi pi-send" (click)="onSave()" [outlined]="true"></p-button>
      </div>
    </div>
  </p-sidebar>
