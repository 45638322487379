import { Inject, Injectable } from '@angular/core';
import { AppService } from '../../app.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileIngestionConfigService {

  private _etlApiBaseUrl: string = '';

  public constructor(private appService: AppService, public http: HttpClient, @Inject('ETL_API_BASE_URL') baseurl: string) {
    this._etlApiBaseUrl = baseurl;
  }

  public get = (creditorId: string): Observable<any> => {
    let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    const apipath: string = 'api/file-ingestion-config?creditorId=creditor/'+creditorId;
    const fullPath: string = this._etlApiBaseUrl + apipath;
    const options = this.appService.getHttpHeaderOptions();

    return this.http.get<any>(fullPath, options);
  }

  public post = (creditorId: string, overwriteAmountDueOnly: boolean, missingInvoicesStatusId: number): Observable<any> => {
    const apipath: string = 'api/file-ingestion-config';
    const fullPath: string = this._etlApiBaseUrl + apipath;
    const options = this.appService.getHttpHeaderOptions();
    const params: any = { CreditorId: +creditorId, OverwriteAmountDueOnly: overwriteAmountDueOnly, MissingInvoicesStatusId: +missingInvoicesStatusId};

    return this.http.post<any>(fullPath, params, options);
  }

  public put = (fileIngestionConfigId: string, overwriteAmountDueOnly: boolean, missingInvoicesStatusId: number): Observable<any> => {
    const apipath: string = 'api/file-ingestion-config';
    const fullPath: string = this._etlApiBaseUrl + apipath;
    const options = this.appService.getHttpHeaderOptions();
    const params: any = { FileIngestionConfigId: fileIngestionConfigId, OverwriteAmountDueOnly: overwriteAmountDueOnly, MissingInvoicesStatusId: +missingInvoicesStatusId};

    return this.http.put<any>(fullPath, params, options);
  }

  public delete = (item: any): Observable<any> => {
    const apipath: string = 'api/file-ingestion-config';
    const fullPath: string = this._etlApiBaseUrl + apipath;
    const options = this.appService.getHttpHeaderOptions();

    const body: any = { 
      FileIngestionConfigId: item.FileIngestionConfigId, 
      OverwriteAmountDueOnly: item.OverwriteAmountDueOnly, 
      MissingInvoicesStatusId: item.MissingInvoicesStatusId};

    const fullOptions = {
        ...options, // Include other options like headers, params, etc.
        body,       // Add the body to the options object
      };

    return this.http.delete<any>(fullPath, fullOptions);
  }



}