import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'common-delete-modal',
  templateUrl: './common-delete-modal.component.html',
  styleUrl: './common-delete-modal.component.css'
})
export class CommonDeleteModalComponent {
  @Input() visible: boolean = false; // Control visibility
  @Input() header: string = 'Delete Confirmation';
  @Input() message: string = 'Are you sure you want to delete?'; // Header text
  @Input() width: string = '30vw'; // Dialog width
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter(); // For two-way binding
  @Output() actionClicked: EventEmitter<void> = new EventEmitter(); // Trigger parent on action

  closeDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible); // Notify parent of visibility change
  }

  triggerAction() {
    this.actionClicked.emit(); // Emit action to parent
    this.closeDialog();
  }
}
