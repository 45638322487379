import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { InvoiceBasicTestComponent } from './components/invoice-basic-test/invoice-basic-test.component';
import { ContactBasicTestComponent } from './components/contact-basic-test/contact-basic-test.component';
import { NotificationReportComponent } from './components/notification-report/notification-report.component';
import { InvoiceSearchComponent } from './components/invoice-search/invoice-search.component';
import { ContactSearchComponent } from './components/contact-search/contact-search.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { RoleSearchComponent } from './components/role-search/role-search.component';
import { RoleEditComponent } from './components/role-edit/role-edit.component';
import { RouteListingComponent } from './components/route-listing/route-listing.component';
import { NavigationNodeEditComponent } from './components/navigation-node-edit/navigation-node-edit.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { FunctionSearchComponent } from './components/function-search/function-search.component';
import { FunctionEditComponent } from './components/function-edit/function-edit.component';
import { MonthlyBillingReportComponent } from './components/monthly-billing-report/monthly-billing-report.component';
import { LandingComponent } from './pages/landing/landing.component';
import { NavigateComponent } from './pages/navigate/navigate.component';
import { EtlIngestionResultComponent } from './pages/etl-ingestion-result/etl-ingestion-result.component';
import { TagsComponent } from './pages/tags/tags.component';
import { FileMappingsComponent } from './pages/file-mappings/file-mappings.component';
import { FileMappingComponent } from './pages/file-mapping/file-mapping.component';
import { NotificationSettingsComponent } from './pages/notification-settings/notification-settings.component';
import { NotificationContactsComponent } from './pages/notification-contacts/notification-contacts.component';
import { FileIngestionConfigComponent } from './pages/file-ingestion-config/file-ingestion-config.component';

const routes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'navigate', component: NavigateComponent },
    { path: 'login', component: LoginComponent },
    { path: 'landing', component: LandingComponent },
    { path: 'routelisting/:navigationNodeId', component: RouteListingComponent, canActivate: [AuthGuardService] },
    { path: 'navigationnode/:navigationNodeId', component: NavigationNodeEditComponent, canActivate: [AuthGuardService] },
    { path: 'navigationnode/:navigationNodeId/:parentNavigationNodeId', component: NavigationNodeEditComponent, canActivate: [AuthGuardService] },
    { path: 'userprofile', component: UserProfileComponent, canActivate: [AuthGuardService] },//
    { path: 'usersearch', component: UserSearchComponent, canActivate: [AuthGuardService] },//
    { path: 'user/:userId', component: UserEditComponent, canActivate: [AuthGuardService] },//
    { path: 'rolesearch', component: RoleSearchComponent, canActivate: [AuthGuardService] },//
    { path: 'role/:roleId', component: RoleEditComponent, canActivate: [AuthGuardService] },//
    { path: 'invoicesearch', component: InvoiceSearchComponent, canActivate: [AuthGuardService] },//
    { path: 'contactsearch', component: ContactSearchComponent, canActivate: [AuthGuardService] },//
    { path: 'invoicejson/:creditorId/:integrationLinkId', component: InvoiceBasicTestComponent, canActivate: [AuthGuardService] },
    { path: 'contactjson/:creditorId/:integrationLinkId', component: ContactBasicTestComponent, canActivate: [AuthGuardService] },
    { path: 'report/notifications', component: NotificationReportComponent, canActivate: [AuthGuardService] },
    { path: 'functionsearch', component: FunctionSearchComponent, canActivate: [AuthGuardService] },//
    { path: 'function/:functionId', component: FunctionEditComponent, canActivate: [AuthGuardService] },
    { path: 'report/monthlybilling', component: MonthlyBillingReportComponent, canActivate: [AuthGuardService] },
    { path: 'etl-ingestion', component: EtlIngestionResultComponent, canActivate: [AuthGuardService]  },
    { path: 'notification-contacts', component: NotificationContactsComponent, canActivate: [AuthGuardService]  },
    { path: 'file-ingestion-config', component: FileIngestionConfigComponent, canActivate: [AuthGuardService]  },
    { path: 'tags', component: TagsComponent, canActivate: [AuthGuardService]  },
    { path: 'etl-ingestion', component: EtlIngestionResultComponent, canActivate: [AuthGuardService] },
    { path: 'notification-settings', component: NotificationSettingsComponent, canActivate: [AuthGuardService]  },
    { path: 'filemappings', component: FileMappingsComponent }, //, canActivate: [AuthGuardService] },
    { path: 'filemapping/:filemappingid', component: FileMappingComponent }, //, canActivate: [AuthGuardService] }
];

@NgModule({
      imports: [RouterModule.forRoot(routes)],
      exports: [RouterModule]
})

export class AppRoutingModule { }
