<div class="container">
    <div *ngIf="!appService.isLogInRoute" class="sidebar shadow-2 pr-2 mr-2" [style.width]="getSideBarWidth()">
      <div class="pt-1 flex justify-content-end">
        <p-button class="ml-3" (click)="hideLabel=true" [rounded]="true" *ngIf="!hideLabel" icon="pi pi-chevron-left" severity="secondary" [text]="true" />
      </div>
     <div *ngIf="hideLabel" class="pt-3">
        <p-button class="ml-3" (click)="hideLabel=false"  icon="pi pi-chevron-right" severity="secondary" [outlined]="true" />
     </div>

        <div>
       
         <svg *ngIf="!hideLabel" class="ml-3 MuiSvgIcon-root SignInPage__logo___1XFE8" style="width:180px;padding-left:8px;" focusable="false" viewBox="0 0 407.8 55.7" aria-hidden="true">
            <rect x="60" y="0.1" width="42.6" height="10.9"></rect>
            <path d="M60,55.7h42.6V44.8h-29V33.7h29v-11l-42.6,0C60.1,33.7,60,44.7,60,55.7z"></path>
            <path d="M158.6,0.2l-0.1-0.1L142,21.9L125.5,0.1l-0.1,0.1V0.1h-13.6v55.6h13.6V18c5.5,7.3,11.1,14.6,16.6,21.9l0,0l0,0 c5.6-7.3,11.1-14.6,16.6-22v37.8h13.6V0.1L158.6,0.2L158.6,0.2z"></path>
            <rect x="181.3" y="0.1" width="13.6" height="55.6"></rect>
            <polygon points="204.2,11 223.1,11 223.1,55.7 236.7,55.7 236.7,11 263.5,11 263.5,55.7 277.1,55.7 277.1,11 295.7,11 295.7,0.1 204.2,0.1 	"></polygon>
            <rect x="305" y="0.1" width="42.6" height="10.9"></rect>
            <path d="M305,55.7h42.6V44.8h-29V33.7h29v-11l-42.6,0C305,33.7,305,44.7,305,55.7z"></path>
            <path d="M397.7,1.7c-3-1.2-7-1.7-12-1.7h-28.7v11h24.7c3.3,0,5.6,0.2,6.8,0.5c6.6,1.7,5.5,8.9-0.2,10.7 c-1.3,0.4-3.4,0.6-6.3,0.6h-25v9.2v1.4v22.5h13.6v-22l13.1,0l7.2,22h15.9l-9.3-25.3C411.2,24.3,411.2,6.9,397.7,1.7z"></path>
            <path d="M40.8,1.7c-3-1.2-7-1.7-12-1.7H0v11h24.7c3.3,0,5.6,0.2,6.8,0.5c6.6,1.7,5.5,8.8-0.2,10.6 c-1.3,0.4-3.4,0.6-6.3,0.6h-7.3v11.1l9,0l7.2,22h15.9l-9.3-26.5C54.3,23.1,54.3,6.9,40.8,1.7z"></path>
            <polygon points="0,55.7 13.6,55.7 13.6,35.9 0,31.1 	"></polygon>
            <path d="M7.4,25.5L0,28.3L7.4,31l1.4,0.5l6.1,2.2v-5.5v-5.5L8.8,25L7.4,25.5z M12.9,28.2v3.1l-4.2-1.5h0l-4.2-1.5 l4.2-1.6h0l4.2-1.6V28.2z"></path>
         </svg>
       </div>
        
      <ul class="sidebar-menu mt-3 pl-3">

        <li [ngClass]="{'nav-item' : true, 'hoverable': true }" *ngFor="let item of menuItems">
         <div class="flex p-0 align-items-center justify-content-between">
    
              <div *ngIf="!hideLabel"  (click)="onClickMenu(item)" class="cursor-pointer flex align-items-center">
                <i [class]="'pi pi-'+getIcon(item.DisplayText)"></i>
                <div>{{ item.DisplayText }}</div>
             </div>
             <div  *ngIf="!hideLabel" (click)="onClickMenu(item)" class="flex-1 cursor-pointer">
              &nbsp;
             </div>
   
             <div *ngIf="hideLabel" [pTooltip]="item.DisplayText" (click)="onClickMenu(item)" class="cursor-pointer flex align-items-center">
               <i [class]="'pi pi-'+getIcon(item.DisplayText)"></i>
             </div>
          

             <div *ngIf="isMasterMember && !hideLabel" class="flex align-items-center cursor-pointer">
              <i (click)="edit(item)" pTooltip="Edit this node"  class="pi pi-pencil"></i>
              <i (click)="add(item)"  pTooltip="Add new below" class="pi pi-plus" ></i>
            </div> 
         </div>
        </li>

      </ul>
    </div>
    <div class="content">
       <div *ngIf="isLoggedIn" class="flex justify-content-end pt-3 pr-3">
        <p-avatar
        tooltipPosition="bottom"
        (click)="myAccountMenu.toggle($event)"
        icon="pi pi-cog"
        styleClass="cursor-pointer"
        shape="circle"
      ></p-avatar>
      <p-menu
        styleClass="text-sm"
        #myAccountMenu
        [model]="myAccountMenuItems"
        [popup]="true"
      ></p-menu>
       </div>

      <div class="flex">
        <div *ngIf="subMenuItems" class="mt-5 ml-5 gap-5 flex flex-row surface-100 border-round-md shadow-2 align-items-center px-5 py-3">
            <div *ngFor="let item of subMenuItems">
                <div (click)="onClickSubMenu(item)" class="cursor-pointer flex align-items-center">
                   <div>{{ item.DisplayText }}</div>
                </div>
            </div>  
          </div>
      </div>
      <div class="router-outlet mt-5">
      
          <router-outlet></router-outlet>
  
      </div>
    </div>
  </div>