import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from '../../app.service';

@Injectable({
    providedIn: 'root'
})

export class EtlIngestionService {

    private _baseUrl: string = '';

    public constructor(
      public appService: AppService, 
      public http: HttpClient, @Inject('API_BASE_URL') baseurl: string) {

        this._baseUrl = baseurl;
    }

    public getCreditors = (): Observable<any> => {
      let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
      const apipath: string = "Etl/GetDefaultSearchParameters";
      const fullPath: string = this._baseUrl + apipath;
      const options = this.appService.getHttpHeaderOptions();

      this.http.get<any>(fullPath, options).subscribe((response: any) => {
          result.next(response);
          result.complete();
      }, error => {
          result.next(error);
      });

      return result;
    }

    public search = (creditors: any, fromDate: any, toDate: any): Observable<any> => {
        this.appService.pageSpinnerVisible = true;

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'Etl/GetIngestionResult';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();
        const params: any = { creditorIds: creditors? creditors: '', fromDate: fromDate, toDate: toDate };

        this.http.post<boolean>(fullPath, params, options).subscribe((response: any) => { 
          this.appService.pageSpinnerVisible = false;
          result.next(response);
          result.complete();

        }, error => {
          this.appService.pageSpinnerVisible = false;
          result.next(error);
        });

        return result;
    }

    public getTags = (creditorId: string, page:number = 1, pageSize: number = 10, sortBy: string = "", sortOrder:string = "ASC"): Observable<any> => {
      let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
      const apipath: string = "CampaignTags/creditors/"+creditorId+'?page='+page+'&pageSize='+pageSize+'&sortBy='+sortBy+'&sortOrder='+sortOrder;
      const fullPath: string = this._baseUrl + apipath;
      const options = this.appService.getHttpHeaderOptions();

      return this.http.get<any>(fullPath, options);
    }
}
